import {
  Input,
  createTheme,
  ActionIcon,
  Button,
  Card,
  Fieldset,
  Notification,
  Tabs,
  Blockquote,
  Select,
  Popover,
  Menu,
} from '@mantine/core';
import classes from '@/style/ProTheme.module.css';

const ProTheme = createTheme({
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '62em',
    lg: '75em',
    xl: '88em',
  },
  primaryColor: 'surf',
  primaryShade: 8,
  colors: {
    surf: [
      '#e4ffff',
      '#d0fffe',
      '#a1fefd',
      '#6ffefc',
      '#4ffefb',
      '#3ffefb',
      '#33fefa',
      '#23e2df',
      '#00CCCC',
      '#00aeac',
    ],
    yellow: [
      '#fffbe1',
      '#fef6cd',
      '#fbec9e',
      '#f9e26a',
      '#f7d93f',
      '#f6d424',
      '#f6d112',
      '#dab901',
      '#c2a400',
      '#a88d00',
    ],
    white: [
      '#e2edef',
      '#f2f8f9',
      '#e6f1f3',
      '#d9e9ed',
      '#cce2e7',
      '#c0dbe0',
      '#b3d4da',
      '#a6ccd4',
      '#9ac5ce',
      '#8dbec8',
    ],
  },
  autoContrast: true,
  fontSizes: {
    xs: '0.875rem',
    sm: '1rem',
    md: '1.25rem',
    lg: '1.5rem',
    xl: '2rem',
  },
  lineHeights: {
    xs: '1rem',
    sm: '1.25rem',
    md: '1.5rem',
    lg: '2rem',
    xl: '2.5rem',
  },
  headings: {
    fontWeight: '500',
    fontFamily: 'Plush, sans-serif',
    sizes: {
      h1: { fontSize: '3.75rem', lineHeight: '4.125rem' },
      h2: { fontSize: '3rem', lineHeight: '3.6rem', fontWeight: '700' },
      h3: { fontSize: '2.5rem', lineHeight: '2.475rem', fontWeight: '700' },
      h4: { fontSize: '1.875rem', lineHeight: '2.0625rem' },
      h5: { fontSize: '1.5rem', lineHeight: '1.8rem' },
      h6: { fontSize: '1.3125rem', lineHeight: '1.70625rem' },
    },
  },
  fontFamily: 'PPObjectSans, sans-serif',
  radius: {
    xs: '0.125rem',
    sm: '0.25rem',
    md: '0.5rem',
    lg: '1rem',
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.625rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
  },
  components: {
    ActionIcon: ActionIcon.extend({
      classNames: {
        root: classes.button,
      },
    }),
    Blockquote: Blockquote.extend({
      classNames: {
        icon: classes['blockquote-icon'],
      },
    }),
    Button: Button.extend({
      classNames: {
        root: classes.button,
      },
    }),
    Card: Card.extend({
      classNames: {
        root: classes.card,
        section: classes['card-section'],
      },
    }),
    Fieldset: Fieldset.extend({
      classNames: {
        root: classes.fieldset,
      },
    }),
    Input: Input.extend({
      classNames: {
        input: classes.input,
      },
    }),
    Menu: Menu.extend({
      classNames: {
        dropdown: classes.dropdown,
        arrow: classes.arrow,
      },
    }),
    Notification: Notification.extend({
      classNames: {
        root: classes.notification,
        title: classes['notification-title'],
        description: classes['notification-description'],
      },
    }),
    Popover: Popover.extend({
      classNames: {
        arrow: classes.arrow,
        dropdown: classes.dropdown,
      },
    }),
    Select: Select.extend({
      classNames: {
        dropdown: classes.dropdown,
        option: classes.option,
      },
    }),
    Tabs: Tabs.extend({
      classNames: {
        list: classes.list,
        tab: classes.tab,
        panel: classes.panel,
      },
    }),
  },
  other: {
    brandColors: {
      sunray: '#FAE67E',
      sunlight: '#FCEFB9',
      daybreak: '#FEF6DC',
      coral: '#ED9FBA',
      surf: '#00CCCC',
      shallows: '#9EDEDA',
      seaSpray: '#D8EFE9',
      sea: '#009BA4',
      deepTeal: '#007187',
      deepOcean: '#004977',
      magenta: '#CE2090',
    },
    colors: {
      richBlack: '#0a1b2c',
      richBlack50: '#101f30',
      deepDark: '#152236',
      borderGray: '#22303E',
      borderBlue: '#25486B',
      iconGray: '#969EAA',
      dangerRed: '#8A1032',
      textGray: '#C4CAD4',
      textLightGray: '#D9D9D9',
      white40: 'rgba(255, 255, 255, 0.4)',
      white60: 'rgba(255, 255, 255, 0.6)',
      deepOcean15: 'rgba(0, 73, 119, 0.15)',
      magenta30: 'rgba(206, 32, 144, 0.30)',
      sunset: '#ffa25e',
      sunray20: 'rgba(250, 230, 126, 0.2)',
    },
  },
});

export default ProTheme;
